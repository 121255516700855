import { generateClientErrorMessage } from "../utils/generateClientErrorMessage"
import { emailApi } from "../config/emailSenderApi"

export const sendContactEmail = async (contactData) => {
    try {
        const res = await emailApi.post('/sendEmail', { ...contactData, company: 'Palangana' })

        if (res.status !== 200) {
            return { success: false, message: generateClientErrorMessage(res) }
        }

        return { success: true, message: res.data.message }

    } catch (error) {
        console.error(error)
        return { success: false, message: generateClientErrorMessage(error) }
    }
}