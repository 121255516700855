import React, { useState, useEffect } from "react";
import VisibilitySensor from "react-visibility-sensor";
import arrowRight from "../../../assets/img/components/arrow-right.webp";
import arrowLeft from "../../../assets/img/components/arrow-left.webp";
import { Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl";

const HomeWeather = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const intl = useIntl();

  const breakpoints = {
    1200: { slidesPerView: 5 },
    768: { slidesPerView: 3 },
    500: { slidesPerView: 2 },
  };

  const onChangeVisibility = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  const fetchWeather = async () => {
    try {
      const apiKey = 'd46f9e5fab12084402d9e9bfe5c3fdaa'; // Sua chave de API do OpenWeather
      const latitude = -25.5146;
      const longitude = -48.5225;

      // URL direta para a API do OpenWeather
      const response = await fetch(
        `https://api.openweathermap.org/data/2.5/forecast?lat=${latitude}&lon=${longitude}&appid=${apiKey}`
      );

      if (!response.ok) throw new Error("Erro ao carregar a previsão do tempo");

      const weatherData = await response.json();
      const forecastData = weatherData.list;

      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      
      const processedData = [];
      const uniqueDates = new Set();

      forecastData.forEach((forecast) => {
        const forecastDate = new Date(forecast.dt * 1000);
        forecastDate.setHours(0, 0, 0, 0);

        // Garantir que a data seja futura e única
        if (forecastDate >= currentDate && !uniqueDates.has(forecastDate.toDateString())) {
          uniqueDates.add(forecastDate.toDateString());
          processedData.push({
            id: forecast.dt,
            weekDay: getDayOfWeek(forecastDate.getDay()),
            date: forecastDate.toLocaleDateString("pt-BR"),
            min: `${(forecast.main.temp_min - 273.15).toFixed(2)}°C`,
            max: `${(forecast.main.temp_max - 273.15).toFixed(2)}°C`,
            current: `${(forecast.main.temp - 273.15).toFixed(2)}°C`,
            moisture: `${forecast.main.humidity}%`,
            wind: forecast.wind?.speed ? `${forecast.wind.speed} m/s` : "N/A",
            weather: forecast.weather[0]?.main || "N/A",
          });
        }
      });

      // Garantir que apenas os primeiros 5 dias sejam retornados
      setData(processedData.slice(0, 5));
    } catch (err) {
      setError(err.message);
    }
  };

  const getDayOfWeek = (dayIndex) => {
    const daysOfWeek = intl.messages.daysOfWeek;
    return daysOfWeek[dayIndex];
  };

  useEffect(() => {
    fetchWeather();
  }, []);

  return (
    <section>
      <div className="homeWeather">
        <VisibilitySensor
          onChange={onChangeVisibility}
          partialVisibility
          minTopValue={200}
        >
          <div>
            <div className="homeWeatherTitle">
              <h1 className={`${isVisible ? "visible" : ""}`}>
                <FormattedMessage id="weatherTitle" />
              </h1>
              <div className="underline"></div>
            </div>
            {error ? (
              <p className="error">{error}</p>
            ) : (
              <div className="homeBlog2">
                <Swiper
                  slidesPerView={1}
                  spaceBetween={20}
                  breakpoints={breakpoints}
                  pagination={{ dynamicBullets: true }}
                  navigation={{
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                  }}
                  modules={[Pagination, Navigation]}
                  className={`${isVisible ? "visible" : ""}`}
                >
                  <div className="swiper-button-prev">
                    <img src={arrowLeft} width={"35px"} alt="Previous" />
                  </div>
                  {data.map((item) => (
                    <SwiperSlide key={item.id}>
                      <div className={`card ${item.weather}`}>
                        <p className="weekDay">{item.weekDay}</p>
                        <p className="date">{item.date}</p>
                        <div className="climate">
                          <p className="min">
                            <FormattedMessage id="minTemperature" />
                            <br />
                            <span>{item.min}</span>
                          </p>
                          <p className="max">
                            <FormattedMessage id="maxTemperature" />
                            <br />
                            <span>{item.max}</span>
                          </p>
                        </div>
                        <p className="current">
                          <FormattedMessage id="currentTemperature" />
                          <br />
                          <span>{item.current}</span>
                        </p>
                        <p className="moisture">
                          <FormattedMessage id="humidity" /> {item.moisture}
                        </p>
                        <p className="wind">
                          <FormattedMessage id="windSpeed" /> {item.wind}
                        </p>
                      </div>
                    </SwiperSlide>
                  ))}
                  <div className="swiper-button-next">
                    <img src={arrowRight} width={"35px"} alt="Next" />
                  </div>
                </Swiper>
              </div>
            )}
          </div>
        </VisibilitySensor>
      </div>
    </section>
  );
};

export default HomeWeather;
