import { api } from '../config/api'
import { generateClientErrorMessage } from 'src/utils/generateClientErrorMessage'

const getHandling = async (limit, offset) => {
  try {
    const res = await api.get(`/getHandling`)

    if (res.status !== 200) {
      return { success: false, message: generateClientErrorMessage(res) }
    }

    return { success: true, data: res.data }
  } catch (error) {
    return { success: false, message: generateClientErrorMessage(error) }
  }
}

export default {
  getHandling,
}