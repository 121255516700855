import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";

function HomeVideo() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <section>
      <VisibilitySensor onChange={onChange} partialVisibility minTopValue={200}>
        <div className="homeVideo">
          <iframe 
            width="1000px" 
            height="500" 
            src="https://www.youtube.com/embed/DEWuNj0FWHI?si=h7imasTr0TKXLi9q" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen>
          </iframe>
        </div>
      </VisibilitySensor>
    </section>
  );
}

export default HomeVideo;
